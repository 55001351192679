class KeyboardNavigation {
  constructor() {
    window.addEventListener('keyup', () => {
      document.body.classList.add('keyboard-user');
    });
    window.addEventListener('click', () => {
      document.body.classList.remove('keyboard-user');
    });
  }
}
export default KeyboardNavigation;
