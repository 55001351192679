export default class Accordion {
  constructor() {
    /**
     * Base class name.
     * @type {string}
     */
    this.baseClassName = 'js-accordion';

    /**
     * Class names used in the accordion.twig template.
     * @type {{accordion: string, trigger: string, body: string, accordionOpen: string}}
     */
    this.classNames = {
      accordion: this.baseClassName,
      accordionOpen: `${this.baseClassName}--open`,
      trigger: `${this.baseClassName}__trigger`,
      body: `${this.baseClassName}__body`,
    };
    /**
     * @type {Element[]}
     */
    this.accordions = Array.from(document.getElementsByClassName(this.classNames.accordion));

    // safety
    if (!this.accordions) return;
    this.initListeners();
  }

  /**
   * Listen for click events on the accordions triggers elements.
   *
   * @method initListeners
   * @memberof Accordion
   */
  initListeners() {
    for (const accordion of this.accordions) {
      const trigger = accordion.querySelector(`.${this.classNames.trigger}`);
      // eslint-disable-next-line no-continue
      if (!trigger) continue;

      const accordionBody = accordion.querySelector(`.${this.classNames.body}`);

      /* on toggle, manipulate the max-height of accordion's body by setting
      it to either 0 or the actual height of the body section.
      That gives us a way to display a CSS-only transition.
      */
      trigger.addEventListener('click', (event) => {
        event.stopPropagation();
        if (this.isAccordionOpen(accordion)) {
          accordionBody.style.maxHeight = 0;
        } else {
          accordionBody.style.maxHeight = `${accordionBody.scrollHeight}px`;
        }
        accordion.classList.toggle(this.classNames.accordionOpen);
      });
    }
  }

  /**
   * Determine if the accordion is open.
   * @param accordion
   * @returns {boolean}
   */
  isAccordionOpen(accordion) {
    return accordion.classList.contains(this.classNames.accordionOpen);
  }
}
