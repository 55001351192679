import 'wicg-inert';
import 'picturefill';
import MicroModal from 'micromodal';
import './utilities/polyfills';
import ZenscrollSetup from './modules/zenscroll-setup';
import Header from './modules/header';

import initModule from './utilities/initModule';

import HeroVideo from './modules/hero-video/hero-video';
import Parallax from './modules/parallax';
import VoiceYourConcernV2 from './modules/voice-your-concern-v2';
import Share from './modules/share';
import Sources from './modules/sources';
import SkipToContent from './modules/skip-to-content';
import VideoModal from './modules/video-modal';
import News from './modules/news/news';
import GetHelp from './modules/get-help/get-help';
import StartConversation from './modules/start-conversation/start-conversation';
import ShareYourStory from './modules/share-your-story/share-your-story';
import KnowTheSigns from './modules/know-the-signs/know-the-signs';
import Stats from './modules/stats_v2';
import FadeIn from './modules/fade-in';
import PolicyMap from './modules/policy-map';
import LinkMonitor from './modules/link-monitor';
import Accordion from './components/accordion';
import TheGuideV2 from './modules/the-guide/the-guide-v2';
import ProgramAndCoalitionForms from './modules/program-and-coalition-forms/program-and-coalition-forms';
import VideoGallery from './modules/video-gallery';
import KeyboardNavigation from './modules/keyboard-navigation';
import FlashMessages from './components/flash-messages';
import Products from './modules/products';

new Header();
new ZenscrollSetup();
new Share();
new Sources();
new SkipToContent();
new VideoModal();
new FadeIn();
new Parallax();
new LinkMonitor();
new Accordion();
new KeyboardNavigation();
new FlashMessages(true);

const modalShowEvent = new Event('modalShow');
const modalCloseEvent = new Event('modalClose');
MicroModal.init({
  onShow: (modal) => modal.dispatchEvent(modalShowEvent),
  onClose: (modal) => modal.dispatchEvent(modalCloseEvent),
});

initModule('js-know-the-signs', KnowTheSigns);
initModule('js-hero-video', HeroVideo);
initModule('js-stats', Stats);
initModule('js-voice-your-concern-v2', VoiceYourConcernV2);
initModule('js-news', News);
initModule('js-get-help', GetHelp);
initModule('js-start-conversation', StartConversation);
initModule('js-share-your-story', ShareYourStory);
initModule('js-program-and-coalition-forms', ProgramAndCoalitionForms);
initModule('js-policy-map', PolicyMap);
initModule('js-the-guide-v2', TheGuideV2);
initModule('js-video-gallery', VideoGallery);
initModule('js-products', Products);
