/* global IntersectionObserver */
import 'element-closest-polyfill';
import 'regenerator-runtime/runtime';
import sleep from '../utilities/sleep';

/**
 * @name PolicyMap
 * @description Class to apply functionality to the Policy Map Section
 */
class PolicyMap {
  constructor(containerElement) {
    /**
     * @name policyMap
     * @type {HTMLElement}
     */
    this.policyMap = containerElement;

    // Stop running if the element doesn't exist
    if (!this.policyMap) {
      return;
    }

    /**
     * @name imageWrapper
     * @type {HTMLElement}
     */
    this.imageWrapper = this.policyMap.querySelector('.js-image-wrapper');

    /**
     * @name mapMarkerGroups
     * @type {Array}
     */
    this.timelineNodes = JSON.parse(this.policyMap.dataset.timelineNodes);

    /**
     * @name mapMarkerGroups
     * @type {Array}
     */
    this.mapMarkerGroups = this.timelineNodes.map((node) => node.map_markers);

    /**
     * @name mapIconURL
     * @type {string}
     */
    this.mapIconURL = this.imageWrapper.dataset.iconUrl;

    /**
     * @name latBounds
     * @type {Object}
     */
    this.latBounds = {
      min: 32.666679,
      max: 42.000325,
    };

    /**
     * @name lngBounds
     * @type {Object}
     */
    this.lngBounds = {
      min: -124.468707,
      max: -114.130846,
    };

    /**
     * @name timeline
     * @type {HTMLElement}
     */
    this.timeline = this.policyMap.querySelector('.js-timeline');

    /**
     * @name timelineNodeTriggers
     * @type {Array}
     */
    this.timelineNodeTriggers = [...this.timeline.querySelectorAll('.js-timeline-node-trigger')];

    /**
     * @name timelineBarForeground
     * @type {HTMLElement}
     */
    this.timelineBarForeground = this.timeline.querySelector('.js-timeline-bar-foreground');

    /**
     * @name policyNumber
     * @type {HTMLElement}
     */
    this.policyNumber = this.policyMap.querySelector('.js-policy-number');

    /**
     * @name numberOfNodes
     * @type {Number}
     */
    this.numberOfNodes = this.timeline.dataset.numberOfNodes;

    /**
     * @name numberOfSegments
     * @type {Number}
     */
    this.numberOfSegments = this.numberOfNodes - 1;

    /**
     * @name nodes
     * @type {Array}
     */
    this.nodes = [];

    /**
     * @name currentNodeIndex
     * @type {Number}
     */
    this.currentNodeIndex = 0;

    /**
     * @name timelineAnimating
     * @type {Boolean}
     */
    this.timelineAnimating = false;

    /**
     * @name timelineInteracted
     * @type {Boolean}
     */
    this.timelineInteracted = false;

    /**
     * @name transitionDuration
     * @type {Number}
     */
    this.transitionDuration = 800;

    /**
     * @name showFinalAnimation
     * @type {Boolean}
     */
    this.showFinalAnimation = !!this.policyMap.dataset.showFinalAnimation;

    this.setupTimeline();
    this.createMarkers();
    this.setUpAnimations();
  }

  /**
   * @method setupTimeline
   * @memberof PolicyMap
   */
  setupTimeline() {
    this.timelineNodeTriggers.forEach((nodeTrigger) => {

      // Set node and node index
      const node = nodeTrigger.closest('.js-timeline-node');
      const { nodeIndex } = node.dataset;

      // Add node to node map
      this.nodes[nodeIndex] = node;

      // Add click event
      nodeTrigger.addEventListener('click', () => {

        // Set timeline interacted to true to stop scroll animations
        this.timelineInteracted = true;

        // trigger timeline animation
        this.triggerAnimation(nodeIndex);
      });
    });
  }

  /**
   * @method triggerAnimation
   * @memberof PolicyMap
   */
  triggerAnimation(nodeIndex, delay = 0) {

    // Stop event if timeline is already animating
    if (this.timelineAnimating) return;

    // Do nothing if the current node is clicked
    if (this.currentNodeIndex === parseInt(nodeIndex, 10)) return;

    // Set timeline state to animating
    this.timelineAnimating = true;

    // Animate to the right
    if (this.currentNodeIndex < nodeIndex) {
      this.animateTimelineRight(nodeIndex, delay);
    }

    // Animate to the left
    if (this.currentNodeIndex > nodeIndex) {
      this.animateTimelineLeft(nodeIndex, delay);
    }
  }

  /**
   * @method animateTimelineRight
   * @memberof PolicyMap
   */
  async animateTimelineRight(destinationNodeIndex, delay = 0) {

    // Check if the node that is being animated to is the last node in the timeline
    const isFinalNode = (this.currentNodeIndex + 1 === this.numberOfSegments);

    // Increment current node index
    this.currentNodeIndex += 1;

    // Animate labels
    this.animateLabels();

    // Animate Markers
    this.animateMarkers(isFinalNode);

    // Increase Number
    this.increasePoliciesNumber();

    // Animate timeline bar
    this.animateTimelineBar(this.currentNodeIndex, this.transitionDuration);

    // Avtivate circle
    this.activateCircle();

    // Recur with animation delay until destination is reached
    if (this.currentNodeIndex < destinationNodeIndex) {
      await sleep(this.transitionDuration);
      await sleep(delay);
      this.animateTimelineRight(destinationNodeIndex, delay);
    } else {
      await sleep(this.transitionDuration);
      this.timelineAnimating = false;
    }
  }

  /**
   * @method animateTimelineLeft
   * @memberof PolicyMap
   */
  async animateTimelineLeft(destinationNodeIndex, delay = 0) {

    // Animate circle
    this.nodes[this.currentNodeIndex].classList.remove('circle-active');

    // Animate Markers
    this.animateMarkers();

    // Decrease Number
    this.decreasePoliciesNumber();

    // Decrement current node index
    this.currentNodeIndex -= 1;

    // Animate labels
    this.animateLabels();

    // Animate timeline bar with delay for animation
    await sleep(50);
    this.animateTimelineBar(this.currentNodeIndex, this.transitionDuration);

    // Recur with animation delay until destination is reached
    if (this.currentNodeIndex > destinationNodeIndex) {
      await sleep(this.transitionDuration);
      await sleep(delay);
      this.animateTimelineLeft(destinationNodeIndex, delay);
    } else {
      await sleep(this.transitionDuration);
      this.timelineAnimating = false;
    }
  }

  /**
   * @method increasePoliciesNumber
   * @memberof PolicyMap
   */
  increasePoliciesNumber() {
    const nodeNumber = this.nodes[this.currentNodeIndex].dataset.numberOfPolicies;
    const currentNumber = this.nodes
      .slice(0, this.currentNodeIndex)
      .map((node) => parseInt(node.dataset.numberOfPolicies, 10))
      .reduce((acc, numberOfPolicies) => acc + numberOfPolicies);
    const newNumber = currentNumber + parseInt(nodeNumber, 10);
    const difference = newNumber - currentNumber;
    const tickSpeed = (this.transitionDuration * 0.9) / difference;

    this.animateNumberUp(currentNumber, newNumber, tickSpeed);
  }

  /**
   * @method decreasePoliciesNumber
   * @memberof PolicyMap
   */
  decreasePoliciesNumber() {
    const nodeNumber = this.nodes[this.currentNodeIndex].dataset.numberOfPolicies;
    const currentNumber = this.nodes
      .slice(0, this.currentNodeIndex + 1)
      .map((node) => parseInt(node.dataset.numberOfPolicies, 10))
      .reduce((acc, numberOfPolicies) => acc + numberOfPolicies);
    const newNumber = currentNumber - parseInt(nodeNumber, 10);
    const difference = currentNumber - newNumber;
    const tickSpeed = (this.transitionDuration * 0.9) / difference;

    this.animateNumberDown(currentNumber, newNumber, tickSpeed);
  }

  /**
   * @method animateNumberUp
   * @memberof PolicyMap
   */
  async animateNumberUp(current, target, tickSpeed) {
    let counter = current;

    // Pause for tick speed
    await sleep(tickSpeed);

    // Increase counter number
    counter += 1;
    this.policyNumber.innerText = counter;

    // Recur if target not reached
    if (counter < target) {
      this.animateNumberUp(counter, target, tickSpeed);
    } else {
      // Set the inner text to the target number to catch counting errors
      this.policyNumber.innerText = target;
    }
  }

  /**
   * @method animateNumberDown
   * @memberof PolicyMap
   */
  async animateNumberDown(current, target, tickSpeed) {
    let counter = current;

    // Pause for tick speed
    await sleep(tickSpeed);

    // Decrease counter number
    counter -= 1;
    this.policyNumber.innerText = counter;

    // Recur if traget not resched
    if (counter > target) {
      this.animateNumberDown(counter, target, tickSpeed);
    } else {
      // Set the inner text to the target number to catch counting errors
      this.policyNumber.innerText = target;
    }
  }

  /**
   * @method animateTimelineBar
   * @memberof PolicyMap
   */
  animateTimelineBar(currentNodeIndex, transitionDuration) {
    const percent = (100 / this.numberOfSegments) * currentNodeIndex;

    this.timelineBarForeground.style.transition = `${transitionDuration / 1000}s`;
    this.timelineBarForeground.style.maxWidth = `${percent}%`;
  }

  /**
   * @method animateLabels
   * @memberof PolicyMap
   */
  animateLabels() {
    setTimeout(() => {
      this.nodes.forEach((node) => {
        node.classList.remove('label-active');
      });
      this.nodes[this.currentNodeIndex].classList.add('label-active');
    }, this.transitionDuration * 0.6);
  }

  /**
   * @method activateCircle
   * @memberof PolicyMap
   */
  async activateCircle() {
    // Animate circle with delay for animation
    await sleep(this.transitionDuration * 0.8);
    this.nodes[this.currentNodeIndex].classList.add('circle-active');
  }

  /**
   * @method animateMarkers
   * @memberof PolicyMap
   */
  animateMarkers(isFinalNode = false) {

    // Find marker group
    const markerGroup = this.policyMap.querySelector(`.policy-map__marker-group--${this.currentNodeIndex}`);

    // Activate marker group if it exists
    if (markerGroup) {
      markerGroup.classList.toggle('policy-map__marker-group--active');
    }

    // Play final animation if its the final node
    if (isFinalNode && this.showFinalAnimation) {
      this.policyMap.classList.add('policy-map--final-animation');
    } else {
      this.policyMap.classList.remove('policy-map--final-animation');
    }
  }

  /**
   * @method createMarkers
   * @memberof PolicyMap
   */
  createMarkers() {
    if (this.mapMarkerGroups) {

      this.mapMarkerGroups.forEach((mapMarkers, index) => {
        const groupElement = document.createElement('div');
        groupElement.classList.add('policy-map__marker-group');
        groupElement.classList.add(`policy-map__marker-group--${index}`);

        if (parseInt(index, 10) === 0) {
          groupElement.classList.add('policy-map__marker-group--active');
        }

        mapMarkers.forEach((marker) => {

          // Create placeholder marker
          const thisMarker = marker;

          // Find relative position on image map
          thisMarker.percentFromLeft = this.lngToPixels(marker.longitude);
          thisMarker.percentFromBottom = this.latToPixels(marker.latitude);

          // Create marker
          thisMarker.element = document.createElement('div');
          thisMarker.element.classList.add('policy-map__marker');
          thisMarker.element.style.position = 'absolute';
          thisMarker.element.style.left = `${thisMarker.percentFromLeft}%`;
          thisMarker.element.style.bottom = `${thisMarker.percentFromBottom}%`;
          thisMarker.element.style.transition = `all 0.4s ease-out ${this.getAnimationDelay() / 1000}s`;
          thisMarker.element.innerHTML = `
            <div class="policy-map__marker-icon">
              <img src="${this.mapIconURL}" alt="map marker" />
            </div>
          `;

          // Add marker to group
          groupElement.append(thisMarker.element);
        });

        // Add group to map
        this.imageWrapper.append(groupElement);
      });
    }
  }

  /**
   * @method setUpAnimations
   * @memberof PolicyMap
   */
  setUpAnimations() {

    // Only apply if IntersectionObserver is supported
    if ('IntersectionObserver' in window) {

      // Create observer
      const observer = new IntersectionObserver((changes) => { this.onChange(changes); }, {
        root: null, // relative to document viewport
        rootMargin: '0px', // margin around root.
        threshold: 1, // visible amount of item shown in relation to root
      });

      // Observe Image window
      observer.observe(this.imageWrapper);

      // Else animate markers
    } else {
      this.triggerAnimation(0, 300);
    }
  }

  /**
   * @method onChange
   * @memberof PolicyMap
   * @param {Array} changes [array to which we apply the intersection observer rules]
   */
  onChange(changes) {
    changes.forEach(async (change) => {
      // Check if timeline has been interacted with
      if (this.timelineInteracted) return;

      if (change.isIntersecting) {
        // trigger scroll animation
        await sleep(200);
        this.triggerAnimation(this.numberOfSegments, 300);

      } else {
        // trigger scroll animation
        this.triggerAnimation(0);
      }
    });
  }

  /**
   * @method getAnimationDelay
   * @memberof PolicyMap
   */
  // eslint-disable-next-line class-methods-use-this
  getAnimationDelay() {
    return Math.floor(Math.random() * 60) * 10;
  }

  /**
   * @method lngToPixels
   * @memberof PolicyMap
   */
  lngToPixels(value) {
    const pixelsFromLeft = this.mapToPixels(
      value,
      this.lngBounds,
      this.imageWrapper.clientWidth,
    );
    return (pixelsFromLeft / this.imageWrapper.clientWidth) * 100;
  }

  /**
   * @method latToPixels
   * @memberof PolicyMap
   */
  latToPixels(value) {
    const pixelsFromBottom = this.mapToPixels(
      value,
      this.latBounds,
      this.imageWrapper.clientHeight,
    );
    return (pixelsFromBottom / this.imageWrapper.clientHeight) * 100;
  }

  /**
   * Convert map a number with one range (latitude or longitude)
   * to it's equivilent in a second range (px width or height of image)
   *
   * @method mapToPixels
   * @memberof PolicyMap
   */
  // eslint-disable-next-line class-methods-use-this
  mapToPixels(value, bounds, max) {
    return Math.floor((parseFloat(value) - bounds.min) * (max / (bounds.max - bounds.min)));
  }
}

export default PolicyMap;
