class FlashMessages {
  constructor(retrieveMessages) {
    /**
     * Base class name.
     * @type {string}
     */
    this.baseClassName = 'js-flash-messages';

    /**
     * Parent class
     * @type {object}
     */
    this.parentElement = document.getElementsByClassName(this.baseClassName)[0];

    /**
     * Default Messages
     * @type {array}
     */
    this.defaultMessages = [];

    /**
     * Messages Stack
     * @type {array}
     */
    this.stack = [];

    /**
     * Flash Message
     * @type {object}
     */
    this.text = {
      success: 'Success.',
      error: 'Error!',
      warning: 'Warning!',
    };

    /**
     * Message Type
     * @type {string}
     */
    this.type = 'default';

    /**
     * Message Duration
     * @type {int}
     */
    this.expiration = 9000;

    /**
     * Message Class
     * @type {string}
     */
    this.class = '';

    /**
     * Message Callbacks
     * @type {array}
     */
    this.callbacks = [];

    /**
     * Fired Callbacks
     * @type {array}
     */
    this.firedCallbacks = [];

    /**
     * Defaults Messages Endpoint
     * @type {string}
     */
    this.defaultsEndpoint = '/wp-json/ctcp-messages/get-defaults';

    /**
     * Messages Endpoint
     * @type {string}
     */
    this.messagesEndpoint = '/wp-json/ctcp-messages/get-messages';

    /**
     * DATE.NOW
     * */
    if (!Date.now) {
      Date.now = function now() { return new Date().getTime(); };
    }

    this.loadDefaultMessages();
    if (retrieveMessages !== undefined && retrieveMessages) {
      this.checkMessages();
    }
  }

  loadDefaultMessages() {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', this.defaultsEndpoint, false);

    xhr.onload = () => {
      if (xhr.status === 200) {
        const defaults = JSON.parse(xhr.response);

        if (defaults && Object.keys(defaults).length > 0) {
          for (const type in defaults) {
            if (typeof defaults[type] === 'string') {
              this.text[type] = defaults[type];
            }
          }
        }
      } else {
        console.log(`Request failed.  Returned status of ${xhr.status}`);
      }
    };

    xhr.send();
  }

  checkMessages() {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', this.messagesEndpoint);

    xhr.onload = () => {
      if (xhr.status === 200) {
        const messages = JSON.parse(xhr.response);

        if (messages && Object.keys(messages).length > 0) {
          let messageType = null;
          for (const type in messages) {
            if (typeof messages[type] === 'object' && Object.keys(messages[type]).length > 0) {
              messageType = messages[type];

              for (const message in messageType) {
                if (typeof messageType[message] === 'object') {
                  this.add(messageType[message].message, { type });
                }
              }
            }
          }

          this.show();
        }
      } else {
        console.log(`Request failed.  Returned status of ${xhr.status}`);
      }
    };

    xhr.send();
  }

  add(text, options, callback) {
    const flashMessage = document.createElement('li');

    let messageId = this.stack.length;
    let duration = this.expiration;
    let className = this.class;
    let messageType = this.type;

    let myCall = callback;

    if (typeof (options) === 'function') {
      myCall = options;
    } else if (options && typeof (options) === 'object' && !Array.isArray(options)) {
      messageType = options.type || messageType;
      messageId = options.id || messageId;
      duration = options.expiration || duration;
      className = options.class || messageType;
    }

    this.firedCallbacks[messageId] = false;

    flashMessage.classList.add(className);
    flashMessage.textContent = text || this.text[messageType];

    const messageClick = () => {
      flashMessage.classList.remove('flash-message-active');

      if (myCall) {
        this.myCall[messageId]();
        this.firedCallbacks[messageId] = true;
      }

      const removeMessage = () => {
        this.parentElement.removeChild(flashMessage);
      };

      setTimeout(() => { removeMessage(); }, 60);
    };

    flashMessage.addEventListener('click', messageClick);

    this.stack[messageId] = {
      element: flashMessage,
      messageType,
      expiration: duration,
    };

    return this;
  }

  clearAll() {
    const messages = document.querySelectorAll(`ul.${this.baseClassName} > li`);
    for (let i = 0, len = messages.length; i < len; i += 1) {
      if (messages[i] !== 'undefined') {
        messages[i].classList.remove('flash-message-active');
        this.parentElement.removeChild(messages[i]);
      }
    }

    this.stack = [];
  }

  show() {
    const addMessage = (element) => {
      element.classList.add('flash-message-active');
    };

    const timeoutAdd = (element) => {
      setTimeout(() => { addMessage(element); }, 10);
    };

    const removeMessage = (elem) => {
      try {
        this.parentElement.removeChild(elem);
      } catch (e) {
        console.log(e);
      }
    };

    const removeMessages = (elem, messageId) => {
      if (elem.classList.contains('flash-message-active')) {
        elem.classList.remove('flash-message-active');
      }

      if (
        (this.firedCallbacks.indexOf(messageId) !== -1 || !this.firedCallbacks[messageId])
        && this.callbacks.indexOf(messageId) !== -1
      ) {
        const callback = this.callbacks[messageId];

        if (callback) {
          this.callbacks[messageId]();
          this.firedCallbacks[messageId] = true;
        }
      }

      setTimeout(() => { removeMessage(elem); }, 10);
    };

    const timeoutRemove = (messageId, message) => {
      setTimeout(() => {
        removeMessages(message.element, messageId);
      }, message.expiration);
    };

    const allMessages = this.stack;

    for (const [messageId, message] of allMessages.entries()) {
      if (typeof message === 'object') {
        const elem = message.element;

        this.parentElement.appendChild(elem);

        timeoutAdd(elem);
        timeoutRemove(messageId, message);
      }
    }
  }
}

export default FlashMessages;
