class Sources {
  constructor() {
    this.sourceModals = document.querySelectorAll('.js-source-modal');
    this.sourceLinks = document.querySelectorAll('.js-source-link');

    this.setupModalEvents();
  }

  setupModalEvents() {
    this.sourceModals.forEach((modal) => {
      modal.addEventListener('modalShow', (event) => this.setSourceLinksTabIndex(event.target, 0));
      modal.addEventListener('modalClose', (event) => this.setSourceLinksTabIndex(event.target, -1));
    });
  }

  setSourceLinksTabIndex(modal, value) {
    const links = modal.querySelectorAll('.js-source-link');
    links.forEach((link) => {
      // eslint-disable-next-line no-param-reassign
      link.tabIndex = value;
    });
  }
}

export default Sources;
