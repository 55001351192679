class HeroVideo {
  constructor(containerElement) {
    /**
     * @type {HTMLElement}
     */
    this.heroSection = containerElement;

    if (!this.heroSection) return;

    /**
     * @type {HTMLElement}
     */
    this.video = this.heroSection.querySelector('.js-video');

    this.initVideos();

    this.setSectionHeight();
    window.addEventListener('resize', () => {
      this.setSectionHeight();
    });
  }

  /**
   * Check if video types are supported by browser
   */
  supportsVideoType(type) {
    let videoChecker;

    // Allow user to create shortcuts, i.e. just "webm"
    const formats = {
      ogg: 'video/ogg; codecs="theora"',
      h264: 'video/mp4; codecs="avc1.42E01E"',
      webm: 'video/webm; codecs="vp8, vorbis"',
      vp9: 'video/webm; codecs="vp9"',
      hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
    };

    if (!videoChecker) {
      videoChecker = document.createElement('video');
    }

    return videoChecker.canPlayType(formats[type] || type);
  }

  /**
   * Init selected videos with the proper extension.
   */
  initVideos() {
    if (!this.video) return;

    const key = this.video.dataset.videoSrc;
    let path = key.split('/');
    let videoSize = '1080';
    const filename = path.pop();
    path = path.join('/');

    if (window.innerWidth <= 720) {
      videoSize = '720';
    }

    if (this.supportsVideoType('webm')) {
      this.video.src = `${path}/webm/${filename}_${videoSize}.webm`;
      this.video.type = 'video/webm';
    } else if (this.supportsVideoType('h264')) {
      this.video.src = `${path}/mp4/${filename}_${videoSize}.mp4`;
      this.video.type = 'video/mp4';
    }
  }

  /**
   * Set the height of the video based on the window's height.
   */
  setSectionHeight() {
    const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.heroSection.style.height = `${height}px`;
  }
}

export default HeroVideo;
