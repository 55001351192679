export default class Bynd {

  static queryAll(selector) {
    return document.querySelectorAll(selector);
  }

  static query(selector) {
    return document.querySelector(selector);
  }

  static addClass(el, className) {
    el.classList.add(className);
  }

  static removeClass(el, className) {
    el.classList.remove(className);
  }

  static toggleClass(el, className) {
    el.classList.toggle(className);
  }

  static hasClass(el, className) {
    return el.classList.contains(className);
  }
}
