export default class KnowTheSigns {
  constructor(containerElement) {
    /**
     * @type {HTMLElement}
     */
    this.knowTheSignsSection = containerElement;
    if (!this.knowTheSignsSection) return;

    /**
     * @type {HTMLElement}
     */
    this.tabs = this.knowTheSignsSection.getElementsByClassName('know-the-signs__tabs_tab');

    for (const tab of this.tabs) {
      tab.addEventListener('click', (event) => { this.clickTab(event); });
    }
  }

  clickTab(event) {
    const tabcontent = this.knowTheSignsSection.getElementsByClassName('tab_content');
    for (const content of tabcontent) {
      content.classList.remove('active');
    }

    const tablinks = this.knowTheSignsSection.getElementsByClassName('tab_link');
    for (const tablink of tablinks) {
      tablink.classList.remove('active');
    }

    const tab = this.knowTheSignsSection.querySelector(`#${event.currentTarget.value}`);
    if (!tab) return;

    tab.classList.add('active');
    event.currentTarget.classList.add('active');
  }
}
