class SkipToContent {
  constructor() {
    this.skipBtn = document.getElementsByClassName('skip')[0];
    this.mainContent = document.getElementById('maincontent');

    if (this.skipBtn && this.mainContent) {
      this.skipBtn.addEventListener(
        'click',
        this.mainContent.focus.bind(this.mainContent),
      );
    }
  }
}

export default SkipToContent;
