/* eslint no-param-reassign: ["error", { "props": false }] */
import lottie from 'lottie-web';

class Stats {
  constructor(containerElement) {
    /**
     * Get stat section
     */
    this.statsSection = containerElement;
    if (!this.statsSection) return;

    /**
     * Path to animation files
     */
    this.animationPath = '/wp-content/themes/dc-ctcp-flavors-v2/animations/';

    /**
     * Stat elements on page
     */
    this.statElements = this.statsSection.querySelectorAll('.js-stat');

    // Container array for stat objects
    this.stats = [];

    setTimeout(() => {
      this.buildStatObjects();
      this.initStatListeners();
    }, 1000);

  }

  /**
   * @method buildStatObjects
   * @memberof Stats
   */
  buildStatObjects() {
    this.statElements.forEach((containerElement) => {

      // Check for container element
      if (!containerElement) throw Error('Stat icon animation container not specified');

      // Get supporting elements
      const triggerElement = containerElement.querySelector('.js-stat-trigger');
      const animationElement = containerElement.querySelector('.js-stat-animation');
      if (!triggerElement) throw Error('The container element must contain a .js-stat-trigger element.');
      if (!animationElement) throw Error('The container element must contain a .js-stat-animation element.');

      // Get the filename
      const { animationFileName } = animationElement.dataset;
      if (!animationFileName) throw Error('No data-animation-file-name specified.');

      let animationFilePath = `${this.animationPath}${animationFileName}.json`;

      if (!this.fileExists(animationFilePath)) {
        const locale = containerElement.getAttribute('locale');
        animationFilePath = `${this.animationPath}${animationFileName}-${locale}.json`;
      }

      // Setup Animation
      const animation = lottie.loadAnimation({
        container: animationElement,
        renderer: 'svg',
        loop: (animationElement.dataset.loopAnimation === '1'),
        autoplay: false,
        path: animationFilePath,
      });
      if (!animation) throw Error('Animation failed to load.');

      this.stats.push({
        // Set elements
        containerElement,
        animationElement,
        triggerElement,

        // Set stat to untriggered state
        triggered: false,

        // Set animation
        animation,
      });
    });
  }

  /**
   * @method initStatListeners
   * @memberof Stats
   */
  initStatListeners() {

    this.stats.forEach((stat) => {

      window.addEventListener('scroll', () => {
        if (this.isInViewport(stat.triggerElement) && !stat.triggered) {
          stat.animation.stop();
          stat.animation.play();
          stat.triggered = true;
        }
      }, false);

    });
  }

  /**
   * Check if element is in viewport
   *
   * @param {HTMLElement} element
   */
  isInViewport(element) {

    // Check if element exists
    if (element === undefined) {
      return false;
    }

    // Get the Elements bounding box
    const distance = element.getBoundingClientRect();

    // Return true if the bounding box is in the viewport
    return (
      distance.top >= 120
      && distance.left >= 0
      && distance.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * Check if a file path exists
   *
   * @param {String} path
   */
  fileExists(path) {
    const http = new XMLHttpRequest();
    http.open('HEAD', path, false);
    http.send();

    return http.status === 200;
  }

}

export default Stats;
