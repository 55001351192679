import Swiper from 'swiper';
import VideoModal from './video-modal';

class VideoGallery {
  constructor(containerEl) {
    this.container = containerEl;

    if (!this.container) return;

    this.swiperEl = this.container.getElementsByClassName('js-swiper-container')[0];

    if (!this.swiperEl) return;

    this.swiperPartials = {
      slides: this.swiperEl.getElementsByClassName('js-video-slide'),
      pagination: this.swiperEl.getElementsByClassName('swiper-pagination')[0],
      navigation: {
        nextEl: this.container.getElementsByClassName('video-gallery__next-btn')[0],
        prevEl: this.container.getElementsByClassName('video-gallery__prev-btn')[0],
      },
    };

    this.videoModal = new VideoModal('video-gallery-modal');

    this.carousel = new Swiper(this.swiperEl, {
      pagination: {
        el: this.swiperPartials.pagination,
        clickable: true,
      },
      navigation: {
        nextEl: this.swiperPartials.navigation.nextEl,
        prevEl: this.swiperPartials.navigation.prevEl,
      },
      watchOverflow: true,
      slidesPerView: 3,
      spaceBetween: 42,
      breakpoints: {
        1279: {
          spaceBetween: 14,
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
      on: {
        init: () => {
          this.determineFirstSlideVisibility();
        },
        resize: () => {
          this.determineFirstSlideVisibility();
        },
      },
    });
    this.carousel.update();

    this.videoCards = Array.from(this.container.getElementsByClassName('js-video-card'));
    this.videoCards.forEach((videoCard) => {
      videoCard.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.closest('[data-video-modal-trigger]')) {
          this.videoModal.open(videoCard.dataset.youtubeId);
        }
      });
      /* Since the element that triggers opening a modal is a div with ARIA
      attributes, it needs a separate keyboard event listener.
      This behavior is default for natively focusable
      elements such as <a> and <button>, but not for a <div>.
      */
      videoCard.addEventListener('keyup', (e) => {
        e.preventDefault();
        if (e.key === 'Enter' || e.key === ' ') {
          this.videoModal.open(videoCard.dataset.youtubeId);
        }
      });
    });
  }

  /**
   * Determine the visibility of the first slide
   * in the swiper. On tablet and above, it should be invisible,
   * since the first slide becomes a hero card above the swiper.
   */
  determineFirstSlideVisibility() {
    const firstSlide = this.swiperPartials.slides[0];
    const shouldBeVisible = window.innerWidth < 768;
    const isCurrentlyVisible = !firstSlide.classList.contains('video-gallery__slide--hidden');
    if (shouldBeVisible !== isCurrentlyVisible) {
      firstSlide.classList.toggle('video-gallery__slide--hidden');
      if (this.carousel) this.carousel.update();
    }
  }
}

export default VideoGallery;
