import simpleParallax from 'simple-parallax-js';

class Parallax {
  constructor() {
    /**
     * @type {Array<HTMLElement>}
     */
    this.parallaxes = document.getElementsByClassName('parallax');

    /* eslint-disable new-cap */
    new simpleParallax(this.parallaxes, {
      orientation: 'right',
      delay: 0.6,
      scale: 1.2,
      transition: 'cubic-bezier(0,0,0,1)',
    });
  }

}
export default Parallax;
