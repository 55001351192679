import Swiper from 'swiper';

/**
 * @name StartConversation
 * @description Class to apply functionality to the Start Conversation component
 */
class StartConversation {
  constructor(containerElement) {

    /**
     * @type {HTMLElement}
     */
    this.startConversationSection = containerElement;

    if (!this.startConversationSection) return;

    /**
     * @type {HTMLElement}
     */
    this.swiperEl = this.startConversationSection.querySelector('.js-swiper-container');

    this.swiperNavigation = {
      prevEl: this.startConversationSection.getElementsByClassName('js-swiper-button-prev')[0],
      nextEl: this.startConversationSection.getElementsByClassName('js-swiper-button-next')[0],
    };

    this.buildCarousel();
  }

  /**
   * Build the carousel
   *
   * @method buildCarousel
   * @memberof StartConversation
   */
  buildCarousel() {
    this.carousel = new Swiper(this.swiperEl, {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: this.swiperNavigation.nextEl,
        prevEl: this.swiperNavigation.prevEl,
      },
      autoplay: {
        delay: 15000,
        disableOnInteraction: true,
      },
    });
  }
}

export default StartConversation;
