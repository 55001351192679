import Swiper from 'swiper';

class Products {
  constructor(containerElement) {
    /**
     * @type {HTMLElement}
     */
    this.productsCardsSection = containerElement;

    /**
     * @type {Element}
     */
    this.productsCards = [...this.productsCardsSection.querySelectorAll('.js-products__card')];

    if (this.productsCards.length > 0) {
      this.initListeners();
    }

    /**
     * @type {Element}
     */
    this.swiperEl = containerElement.querySelector('.js-products__swiper');

    this.buildProductsCarousel();
  }

  /**
   * Sets up all listener events
   *
   * @method initListeners
   * @memberof Products
   */
  initListeners() {
    const forwardTransform = 'translate(-50%, -50%) rotateY(0deg)';
    const backwardTransform = 'translate(-50%, -50%) rotateY(180deg)';

    this.productsCards.forEach((productCard) => {
      productCard.addEventListener('click', (event) => {
        const target = event.currentTarget;
        const front = target.querySelector('.js-products__card-front');
        const back = target.querySelector('.js-products__card-back');

        if (front.style.transform === backwardTransform) {
          front.style.transform = forwardTransform;
          back.style.transform = backwardTransform;
        } else {
          front.style.transform = backwardTransform;
          back.style.transform = forwardTransform;
        }
      });
    });
  }

  /**
   * Builds the News carousel
   *
   * @method buildProductsCarousel
   * @memberof Products
   */
  buildProductsCarousel() {

    if (!this.swiperEl) return;

    const swiperPartials = {
      pagination: this.swiperEl.querySelector('.swiper-pagination'),
    };

    const slider = new Swiper(this.swiperEl, {
      pagination: {
        el: swiperPartials.pagination,
        clickable: true,
      },
      loop: false,
      a11y: true,
      watchOverflow: true,
      slidesPerView: 2,
      spaceBetween: 10,
      centerInsufficientSlides: true,
      breakpoints: {
        1220: {
          slidesPerView: 2,
        },
        767: {
          slidesPerView: 1,
        },
      },
    });
    slider.update();
  }
}

export default Products;
