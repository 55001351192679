class Header {
  constructor() {

    this.header = document.querySelector('.js-header');

    /**
     * @name headerTop
     * @type {HTMLElement}
     */
    this.headerTop = this.header.querySelector('.js-header-top');

    /**
     * @name headerBottom
     * @type {HTMLElement}
     */
    this.headerBottom = this.header.querySelector('.js-header-bottom');

    /**
     * @name toggleNav
     * @type {HTMLElement}
     */
    this.toggleNav = this.header.querySelector('.js-nav-burger');

    /**
     * @name nav
     * @type {HTMLElement}
     */
    this.nav = this.header.querySelector('.js-navigation');

    /**
     * @name langBtn
     * @type {HTMLElement}
     */
    this.langBtn = this.header.querySelector('.js-lang-desktop-btn');

    /**
     * @name langOpts
     * @type {HTMLElement}
     */
    this.langOpts = this.header.querySelector('.js-desktop-lang-opts');

    /**
     * @name shareBtn
     * @type {HTMLElement}
     */
    this.shareBtn = this.header.querySelector('.js-share-btn');

    /**
     * @name mobLangBtn
     * @type {HTMLElement}
     */
    this.mobLangBtn = this.header.querySelector('.js-mob-lang-btn');

    /**
     * @name mobLangOpts
     * @type {HTMLElement}
     */
    this.mobLangOpts = this.header.querySelector('.js-mob-lang-opts');

    /**
     * @name headerMenuLink
     * @type {HTMLElement}
     */
    this.headerMenuLink = this.header.querySelectorAll('.js-menu-link');

    /**
     * @name headerModal
     * @type {HTMLElement}
     */
    this.headerModal = this.header.querySelector('.js-header-modal');

    /**
     * @name headerModalOpen
     * @type {HTMLElement}
     */
    this.headerModalOpenButtons = [...this.header.querySelectorAll('.js-header-modal-open')];

    /**
     * @name headerModalClose
     * @type {HTMLElement}
     */
    this.headerModalCloseButton = this.header.querySelector('.js-header-modal-close');

    /**
     * @name body
     * @type {HTMLElement}
     */
    this.body = document.querySelector('body');

    this.initStickyHeader();
    this.addBurgerListener();
    this.addPageClickListener();
    this.addHeaderModalListeners();
    this.addlangListener();
    this.addShareListener();
    this.addEscapeListener();
    this.addResizeListener();
    this.addMobLangListener();
    this.closeEverything();
    this.addCurrentPathCheck();
  }

  /**
   * close any open menus
   */
  closeEverything() {
    if (this.langOpts) {
      this.langOpts.classList.remove('open');
    }
    this.langBtn.classList.remove('open');
    this.nav.classList.remove('open');
  }

  /**
   * Set up sticky header functionality
   */
  initStickyHeader() {
    const scrolledClass = 'header--scrolled';

    // Check for when user scrolls past the height of the heager-top
    window.addEventListener('scroll', () => {
      if (window.scrollY >= this.headerTop.offsetHeight) {
        this.header.classList.add(scrolledClass);
        this.nav.classList.add(scrolledClass);
      } else {
        this.header.classList.remove(scrolledClass);
        this.nav.classList.remove(scrolledClass);
      }
    });
  }

  /**
   * Check if path matches curent url and underline if so
   */
  addCurrentPathCheck() {
    const CurrentPath = 'link--active';
    this.headerMenuLink.forEach((link) => {
      if (window.location.href === link.href || window.location.href.indexOf(`${link.href}#`) !== -1) {
        link.classList.add(CurrentPath);
      }
    });
  }

  /**
   * Toggle mobile and tablet navigation
   */
  addBurgerListener() {
    this.toggleNav.addEventListener('click', () => {
      this.nav.classList.toggle('open');
      this.toggleNav.classList.toggle('open');
    });
  }

  /**
   * Toggle desktop languages menu
   */
  addlangListener() {
    this.langBtn.addEventListener('click', () => {
      if (this.langOpts) {
        this.langOpts.classList.toggle('open');
      }
      this.langBtn.classList.toggle('open');
    });
  }

  /**
   * Toggle mobile navigation languages menu
   */
  addMobLangListener() {
    this.mobLangBtn.addEventListener('click', () => {
      if (this.mobLangOpts) {
        this.mobLangOpts.classList.toggle('open');
      }
      this.mobLangBtn.classList.toggle('open');
    });
  }

  /**
   * Add listener to the page to close open menus when the page is clicked
   */
  addPageClickListener() {
    this.body.addEventListener('click', (e) => {
      const checkNotNav = e.target.classList.contains('js-menu-item');
      const checkLangOpen = this.langBtn.classList.contains('open');
      const checkNavOpen = this.nav.classList.contains('open');
      if (checkNavOpen) {
        if (checkNotNav) {
          this.nav.classList.add('open');
          this.toggleNav.classList.add('open');
        } else {
          this.nav.classList.remove('open');
          this.toggleNav.classList.remove('open');
        }
      } else if (checkLangOpen) {
        if (checkNotNav) {
          this.langBtn.classList.add('open');
          this.langOpts.classList.add('open');
        } else {
          this.langBtn.classList.remove('open');
          this.langOpts.classList.remove('open');
        }
      }
    });
  }

  /**
   * Add listener to control the header modal
   */
  addHeaderModalListeners() {
    this.headerModalOpenButtons.forEach((button) => {
      button.addEventListener('click', () => {
        this.headerModal.classList.add('header__modal--open');
        // Modal is opened using a data attribute
        this.body.classList.add('header-modal-open');
        this.body.style.overflow = 'hidden';
      });
    });
    this.headerModalCloseButton.addEventListener('click', () => {
      this.headerModal.classList.remove('header__modal--open');
      // Modal is opened using a data attribute
      this.body.classList.remove('header-modal-open');
      this.body.style.overflow = 'scroll';
    });
  }

  /**
   * Add listener to share button to close any open menu
   */
  addShareListener() {
    this.shareBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.closeEverything();
    });
  }

  /**
   * Add listener to escape key to close any open menu
   */
  addEscapeListener() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        this.closeEverything();
      }
    });
  }

  /**
   * Add listener to close menu's on window resize
   */
  addResizeListener() {
    window.addEventListener('resize', () => {
      this.closeEverything();
    });
  }
}

export default Header;
