class Share {
  constructor() {

    /* TODO: consider splitting the responsibilities, so btn--share and share-btn
    * classes are responsible for styling only, and js-share-btn for the JS functionality */
    /**
     * @type {HTMLElement}
     */
    this.shareBtns = document.querySelectorAll('.btn--share, .share-btn, .js-share-btn');

    /**
     * @type {HTMLElement}
     */
    this.shareContainer = document.getElementsByClassName('social-button--url')[0];

    /**
     * @type {HTMLElement}
     */
    this.shareUrl = document.getElementsByClassName('js-share-url')[0];

    /**
     * @type {Array<HTMLElement>}
     */
    this.socialButtons = document.querySelectorAll('.share-site__buttons button');

    /**
     * @type {Array<HTMLElement>}
     */
    this.closeModal = document.querySelector('.share__modal-content button');

    /**
     * @type {HTMLElement}
     */
    this.clipboardSuccess = document.getElementsByClassName('share__clipboard-success')[0];

    /**
     * @type {HTMLElement}
     */
    this.clipboardError = document.getElementsByClassName('share__clipboard-error')[0];

    /**
     * @type {Object}
     */
    this.url = {
      facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
      twitter: 'https://twitter.com/intent/tweet?url=',
      whatsapp: 'https://api.whatsapp.com/send?text=',
    };

    this.baseShareUrl = this.shareUrl.value;

    this.listenForShare();
    this.copyShareUrl();
    this.shareToSocial();
  }

  /**
   * Add section path to the site url
   *
   * @method addSection
   * @memberof Share
   */
  addSection(section) {
    this.section = section;
    this.shareUrl.value = `${this.baseShareUrl}#${this.section}`;
  }

  /**
   * Add attribute type for Google Analytics tracking
   *
   * @method addAttributeType
   * @memberof Share
   */
  addAttributeType(value) {
    this.attributeType = value;
  }

  /**
   * Listens for a click on the share button and opens modal
   *
   * @method listenForShare
   * @memberof Share
   */
  listenForShare() {
    for (const share of this.shareBtns) {
      if (share !== undefined) {
        share.addEventListener('click', () => {
          if (share.dataset.section && share.dataset.attributeType && share.dataset.anchor) {
            this.addAttributeType(share.dataset.attributeType);
            this.addSection(share.dataset.section);
            this.anchor = share.dataset.anchor;
          } else {
            this.shareUrl.value = this.baseShareUrl;
          }
          share.setAttribute('aria-expanded', true);
          // Modal is opened using a data attribute
        });
      }
    }
  }

  /**
   * Listens for a click on the url text and copies it to the clipboard
   *
   * @method copyShareUrl
   * @memberof Share
   */
  copyShareUrl() {
    this.shareContainer.addEventListener('click', () => {
      this.shareUrl.select();
      this.shareUrl.setSelectionRange(0, 99999);
      try {
        const status = document.execCommand('copy');
        this.shareUrl.blur();
        if (!status) {
          this.clipboardError.classList.add('share__clipboard-error--visible');
        } else {
          this.clipboardSuccess.classList.add('share__clipboard-success--visible');
        }
      } catch (err) {
        this.clipboardError.classList.add('share__clipboard-error--visible');
      }
    });
  }

  /**
   * Listens for a click on one of the social media buttons, opens it in a new
   * window and shares the page
   *
   * @method shareToSocial
   * @memberof Share
   */
  shareToSocial() {
    const loc = document.location.origin + document.location.pathname;
    for (const button of this.socialButtons) {
      if (!button.classList.contains('social-button--url')) {
        button.addEventListener('click', () => {
          const socialClasses = button.classList[1].split('--');
          const socialNetwork = socialClasses[socialClasses.length - 1];
          let url = loc.slice(0, -1);

          if (this.section) {
            url += `/?share=${this.section}`;
          }

          if (this.anchor) {
            url += `#${this.anchor}`;
          }

          url = encodeURIComponent(url);

          this.openCenteredShareWindow({
            url: `${this.url[socialNetwork]}${url}`,
            title: 'share',
            w: 600,
            h: 460,
          });
        });
      }
    }
  }

  /**
   * Open a new 'Share' window that is centered on the currently active screen.
   * @param url - URL to open
   * @param title - window title
   * @param w - window width
   * @param h - window height
   * Source: https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
   * Note: I've removed the systemZoom dependency was causing
   * some strange 'Share' window size depending on the size of the main window
   */
  openCenteredShareWindow({
    url, title, w, h,
  }) {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth || document.documentElement.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight;

    const left = ((width - w) / 2) + dualScreenLeft;
    const top = ((height - h) / 2) + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w},
      height=${h},
      top=${top},
      left=${left}
      `,
    );

    if (window.focus && newWindow) newWindow.focus();
  }
}

export default Share;
