import Bynd from './bynd';

/**
 * @type {String}
 */
const visibleClass = 'visible';

/**
 * @type {String}
 */
const fadeInClass = 'fade-in';

export default class FadeIn {
  constructor() {

    /**
     * @type {Array<HTMLElement>}
     */
    this.fadeInElements = document.getElementsByClassName('js-fade-in');

    // safety
    if (!this.fadeInElements) return;
    this.setDefaultState();
    this.initListeners();
  }

  /**
   * Set All elements to default state
   *
   * @method setDefaultState
   * @memberof FadeIn
   */
  setDefaultState() {
    for (const fadeInElement of this.fadeInElements) {
      Bynd.addClass(fadeInElement, fadeInClass);
      if (this.isInViewport(fadeInElement) && !Bynd.hasClass(fadeInElement, visibleClass)) {
        Bynd.addClass(fadeInElement, visibleClass);
      }
    }
  }

  /**
   * Listen for scroll
   *
   * @method initListeners
   * @memberof FadeIn
   */
  initListeners() {

    window.addEventListener('scroll', () => {
      for (const fadeInElement of this.fadeInElements) {
        if (this.isInViewport(fadeInElement) && !Bynd.hasClass(fadeInElement, visibleClass)) {
          Bynd.addClass(fadeInElement, visibleClass);
        }
      }

    }, false);
  }

  /**
   * Check if element is in view
   *
   * @method isInViewport
   * @memberof FadeIn
   */
  isInViewport(elem) {
    if (elem === undefined) {
      return false;
    }
    const distance = elem.getBoundingClientRect();

    return (
      distance.top >= 0
      && distance.bottom <= (window.innerHeight
        || document.documentElement.clientHeight)
    );
  }

}
