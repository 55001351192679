/**
 * @name GetHelp
 * @description Class to apply functionality to the Get Help component
 */
class GetHelp {
  constructor(containerElement) {
    /**
     * @type {HTMLElement}
     */
    this.getHelpSection = containerElement;

    /**
     * @type {Array<HTMLElement>}
     */
    this.getHelpMore = [...this.getHelpSection.querySelectorAll('.js-get-help__article--more a')];

    this.loadMore();
  }

  loadMore() {
    const buttons = this.getHelpMore;

    const moreFunc = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const parentElem = e.currentTarget.closest('.get-help__articles');

      const hiddenChilds = parentElem.querySelectorAll('.get-help__article--hidden');

      const ammountElements = hiddenChilds.length > 3 ? 3 : hiddenChilds.length;

      for (let j = 0; j < ammountElements; j += 1) {
        hiddenChilds[j].style.display = 'block';
        console.log(hiddenChilds[j]);
      }

      if (hiddenChilds.length <= 3) {
        e.currentTarget.closest('.js-get-help__article--more').remove();
      }
    };

    buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        moreFunc(e);
      });
    });
  }
}

export default GetHelp;
