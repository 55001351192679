/**
 * @class LinkMonitor
 */
class LinkMonitor {
  constructor() {
    const links = document.querySelectorAll('.js-link-monitor a');

    if (links.length === 0) {
      return;
    }

    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        const ID = this.getYouTubeID(event.currentTarget);
        if (ID !== false) {
          this.createVideo(`https://www.youtube.com/embed/${ID}?autoplay=1&origin=https%3A%2F%2Fflavorshookkids.org&enablejsapi=1`);
          event.preventDefault();
        }
      });
    });
  }

  createVideo(src) {
    const modal = document.createElement('div');
    modal.setAttribute('class', 'js-video-modal');
    modal.addEventListener('click', (event) => {
      this.destroyVideo(event.currentTarget);
    });

    document.body.appendChild(modal);

    modal.innerHTML = `
      <div class="js-video-modal--wrapper">
        <div class="js-video-modal--container">
          <iframe
            src="${src}"
            id="video-iframe"
            frameborder="0"
            allowfullscreen
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          >
          </iframe>
        </div>
      </div>
    `;
  }

  destroyVideo(modal) {
    if (modal) {
      modal.parentNode.removeChild(modal);
    }
  }

  getYouTubeID(link) {
    const url = link.href;
    if (url !== undefined || url !== '') {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
      const match = url.match(regExp);

      if (match && match[2].length === 11) {
        return match[2];
      }
    }

    return false;
  }
}

export default LinkMonitor;
