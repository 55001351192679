import Swiper from 'swiper';

/**
 * @name News
 * @description Class to apply functionality to the News component
 */
class News {
  constructor(containerElement) {

    /**
     * @type {Array<HTMLElement>}
     */
    this.newsSection = containerElement;

    /**
     * @type {HTMLElement}
     */
    this.newsCarousel = this.newsSection.getElementsByClassName('js-news__swiper')[0];

    this.buildNewsCarousel();
  }

  /**
   * Builds the News carousel
   *
   * @method buildNewsCarousel
   * @memberof News
   */
  buildNewsCarousel() {
    const customFunctionRender = function (swiper, current, total) {
      const sliderPerView = swiper.params.slidesPerView;
      let page = 0;
      let customPaginationHtml = '';
      let customClass = '';

      for (let i = 0; i < total; i += 1) {
        page = i + 1;

        if (page === 1 || sliderPerView === 1
          || page % (sliderPerView + 1) === 0 || page === total) {
          customClass = current === i ? 'swiper-pagination-bullet-active' : '';
          customPaginationHtml += `<span class="swiper-pagination-bullet ${customClass}"></span>`;
        } else {
          customPaginationHtml += '<span class="swiper-pagination-bullet swiper-pagination-bullet-inactive"></span>';
        }
      }

      return customPaginationHtml;
    };

    new Swiper(this.newsCarousel, {
      direction: 'horizontal',
      spaceBetween: 20,
      dynamicBullets: true,
      watchOverflow: true,
      speed: 900,
      slidesPerView: 3,
      allowTouchMove: true,
      autoplay: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'custom',
        renderCustom: customFunctionRender,
      },
      breakpoints: {
        1279: {
          slidesPerView: 2,
          spaceBetween: 12,
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 14,
        },
      },
    });
  }
}

export default News;
