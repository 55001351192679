import axios from 'axios';

class ProgramAndCoalitionForms {
  constructor(containerElement) {

    /**
     * @type {HTMLElement}
     */
    this.pcfSection = containerElement;

    // ZIP SEARCH //
    /**
     * @type {HTMLElement}
     */
    this.zipSearchBtn = this.pcfSection.querySelector('.js-zipsearch-btn');

    /**
     * @type {Array<HTMLElement>}
     */
    this.zipField = this.pcfSection.querySelector('.js-zipsearch-input');

    /**
     * @type {HTMLElement}
     */
    this.zipSearchResults = this.pcfSection.querySelector('.js-zipsearch-results');

    /**
     * @type {HTMLElement}
     */
    this.zipSearchResultsTitle = this.pcfSection.querySelector('.js-zipsearch-results-title');

    /**
     * @type {HTMLElement}
     */
    this.zipSearchResultsContent = this.pcfSection.querySelector('.js-zipsearch-results-content');

    /**
     * @type {HTMLElement}
     */
    this.zipSearchError = this.pcfSection.querySelector('.js-zipsearch-error');

    // JOIN COALITION //
    /**
     * @type {HTMLElement}
     */
    this.joinForm = this.pcfSection.querySelector('.js-form-join');
    /**
     * @type {HTMLElement}
     */
    this.joinFormInputName = this.pcfSection.querySelector('.js-input-name');

    /**
     * @type {HTMLElement}
     */
    this.joinFormInputCounty = this.pcfSection.querySelector('.js-input-county');

    /**
     * @type {HTMLElement}
     */
    this.joinSuccessDiv = this.pcfSection.querySelector('.js-join-success');

    /**
     * @type {HTMLElement}
     */
    this.joinSuccessSubmitBtn = this.pcfSection.querySelector('.js-join-submit');

    if (!this.pcfSection) {
      return;
    }

    if (this.zipSearchBtn) {
      this.zipSearchBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.zip = this.zipField.value;
        this.zipLookUp();
      });
    }

    if (this.zipField) {
      this.zipField.addEventListener('keydown', (e) => {
        if ((e.keyCode >= 48 && e.keyCode <= 57)
          || e.keyCode === 8
          || (e.metaKey && e.keyCode === 86)
          || (e.metaKey && e.keyCode === 67)) {
          this.zipField.classList.remove('error');
          this.zipSearchError.classList.add('hide');
        } else {
          e.preventDefault();
        }
      });

      this.zipField.addEventListener('input', () => {
        this.zipSearchBtn.disabled = this.zipField.value.length < 5;
      });
    }

    this.joinSuccessSubmitBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.generateCoalitionEmail();
      this.submitJoinForm();

    });

    this.joinFormInputName.addEventListener('input', () => {
      this.joinSuccessSubmitBtn.disabled = this.joinFormInputName.value === '' || this.joinFormInputCounty.value === '';
    });

    this.joinFormInputCounty.addEventListener('input', () => {
      this.joinSuccessSubmitBtn.disabled = this.joinFormInputName.value === '' || this.joinFormInputCounty.value === '';
    });

  }

  zipLookUp() {
    // Clear error
    this.zipSearchError.classList.add('hide');
    this.zipField.classList.remove('error');

    const host = window.location.hostname === 'localhost' ? 'dc-ctcp-flavors.com' : window.location.hostname;
    const apiURL = `${window.location.protocol}//${host}/wp-json/ctcp-pl/v1/zipcode/${this.zip}`;

    axios({
      method: 'get',
      url: apiURL,
    })
      .then((response) => {
        if (response.request.readyState === 4 && response.status === 200) {
          if (!response.data) {
            this.showError();
          } else {
            this.populateZipCode(response.data);
          }
        } else {
          this.showError();
        }
      }).catch((error) => {
      // eslint-disable-next-line no-console
        console.warn(error);
        this.showError();
      });
  }

  showError() {
    this.zipField.classList.add('error');
    this.zipSearchError.classList.remove('hide');
    this.zipSearchResults.classList.add('hidden');
  }

  populateZipCode(data) {
    this.zipSearchResults.classList.remove('hidden');
    this.zipSearchResultsTitle.innerHTML = data.ctcp_pl_department;
    this.zipSearchResultsContent.innerHTML = `${data.ctcp_pl_address}`;
    this.zipSearchResultsContent.innerHTML += `<br>${data.ctcp_pl_zipcode}`;
    this.zipSearchResultsContent.innerHTML += `<br>${data.ctcp_pl_contact_no}`;
    this.zipSearchResultsContent.innerHTML += `<br>${data.ctcp_pl_email_address}`;
  }

  /**
   * Creates email message out of the fields that the user has entered
   * Opens new window to send email
   *
   * @method generateCoalitionEmail
   * @memberof JoinCoalition
   */
  generateCoalitionEmail() {

    let emailContent = 'Hi,%0D%0A%0D%0A';
    emailContent += 'I just recently read through the flavorshookkids.org site and I\'m interested in joining a local tobacco control coalition. %0D%0A';
    emailContent += 'Please send me more information on how I can get involved.%0D%0A%0D%0A';
    emailContent += 'Here is my information:%0D%0A';
    emailContent += `- Name: ${this.joinFormInputName.value}%0D%0A`;
    emailContent += `- County: ${this.joinFormInputCounty.value}%0D%0A`;

    window.open(`mailto:tcswebmaster@cdph.ca.gov?subject=I%27m%20interested%20in%20joining%20a%20tobacco%20control%20coalition&body=${(emailContent)}`, '_blank');

  }

  submitJoinForm() {
    // Show success popup
    this.joinSuccessDiv.classList.add('visible');
    this.joinForm.style.display = 'none';

  }

}

export default ProgramAndCoalitionForms;
