/* eslint-disable no-param-reassign */
import autocomplete from 'autocompleter';
import axios from 'axios';

/**
 * @class VoiceYourConcernV2
 */
class VoiceYourConcernV2 {
  constructor(containerElement) {
    /**
     * @name voiceYourConcernSection
     * @type {HTMLElement}
     */
    this.voiceYourConcernSection = containerElement;

    // Stop running if the element doesn't exist
    if (!this.voiceYourConcernSection) return;

    /**
     * @type {HTMLElement}
     */
    this.modalNode = this.voiceYourConcernSection.parentNode;

    /**
     * @type {HTMLElement}
     */
    this.nameInput = this.voiceYourConcernSection.querySelector('.js-name-input');

    /**
     * @type {HTMLElement}
     */
    this.letterContentV2 = this.voiceYourConcernSection.querySelector('.js-letter-content-v2');

    /**
     * @type {HTMLElement}
     */
    this.closingNameDisplay = this.voiceYourConcernSection.querySelector('.js-letter-closing-name');

    /**
     * @type {HTMLElement}
     */
    this.locationSelectInput = this.voiceYourConcernSection.querySelector('.js-location-select-input');

    /**
     * @type {String}
     */
    this.locationOtherLabel = this.locationSelectInput.dataset.otherLabel;

    /**
     * @type {HTMLElement}
     */
    this.recipientSelect = this.voiceYourConcernSection.querySelector('.js-recipient-select');

    /**
     * @type {HTMLElement}
     */
    this.recipientInput = this.voiceYourConcernSection.querySelector('.js-recipient-input');

    /**
     * @type {HTMLElement}
     */
    this.recipientLabel = this.voiceYourConcernSection.querySelector('.js-recipient-label');

    /**
     * @type {HTMLElement}
     */
    this.generateLetterButton = this.voiceYourConcernSection.querySelector('.js-generate-letter-button');

    /**
     * @type {HTMLElement}
     */
    this.letterSentMessage = this.voiceYourConcernSection.querySelector('.js-generate-letter-success-message');

    /**
     * @type {HTMLElement}
     */
    this.letterContentPreview = this.voiceYourConcernSection.querySelector('.js-generate-letter-preview-content');

    /**
     * @type {HTMLElement}
     */
    this.generateLetterButtonWrapper = this.voiceYourConcernSection.querySelector('.js-generate-letter-button-wrapper');

    /**
     * @type {HTMLElement}
     */
    this.letterPreviewContainer = this.voiceYourConcernSection.querySelector('.js-generate-letter-preview');

    /**
     * @type {HTMLElement}
     */
    this.letterBatches = this.voiceYourConcernSection.querySelectorAll('.js-letter-batch');

    /**
     * @type {string}
     */
    this.englishSiteLink = this.voiceYourConcernSection.dataset.englishSiteLink;

    /**
     * @type {Array}
     */
    this.locations = [];

    /**
     * @type {Array}
     */
    this.locationOptions = [];

    /**
     * @type {Array}
     */
    this.batches = {};

    /**
     * @type {Number}
     */
    this.locationsPerRequest = 100;

    /**
     * @type {Number}
     */
    this.selectedBatch = 0;

    /**
     * A list of selected contacts.
     * When a selection is invalid, a list is set to undefined.
     * When 'Other' option is selected, it's an empty array.
     * @type {Array | undefined}
     */
    this.selectedContacts = undefined;

    this.loadLocations(0);
    this.registerBatches();
    this.initNameinputEventListener();
    this.initRecipientsInputEvents();
    this.initGenerateLetterButtonEvents();
  }

  /**
   * registerBatches
   */
  registerBatches() {
    this.letterBatches.forEach((element) => {

      if (!element.dataset.locations) return;

      const locations = JSON.parse(element.dataset.locations);

      if (!locations) return;

      locations.forEach((location) => {
        this.batches[location] = element.dataset.batch;
      });
    });

    this.displayBatch(this.selectedBatch);
  }

  /**
   * loadLocations
   */
  loadLocations(offset) {
    axios.get(`${this.englishSiteLink}/wp-json/wp/v2/ctcp_location?per_page=${this.locationsPerRequest}&offset=${offset}&filter[orderby]=title&order=asc&_fields=title,acf,id`)
      .then((response) => {
        const newOffset = offset + this.locationsPerRequest;

        // Load Locations with meta data
        response.data.forEach((location) => {
          this.locations[location.title.rendered] = location.acf.location_contacts;
        });

        // Append locations
        this.locationOptions = [
          ...this.locationOptions,
          ...response.data.map((location) => ({
            label: location.title.rendered,
            value: location.id,
          })),
        ];

        // Recurse
        if (response.data.length >= this.locationsPerRequest) {
          this.loadLocations(newOffset);
        } else {
          this.setUpLocationSelect();
          this.letterContentV2.classList.remove('letter-content-v2--loading');
        }
      });
  }

  /**
   * preventNumericalInput
   */
  preventNumericalInput(event) {
    if (event.which > 47 && event.which < 58) {
      event.preventDefault();
    }
  }

  /**
   * setUpLocationSelect
   */
  setUpLocationSelect() {

    // Disable recipient select when user types in location search
    this.locationSelectInput.addEventListener('input', () => {
      this.recipientInput.value = '';
      this.recipientInput.disabled = true;
      this.selectedContacts = undefined;
      this.recipientSelect.classList.remove(
        'recipient-select--enabled',
        'recipient-select--static',
      );

      this.validateForm();
    });

    // prevent numerical inputs
    this.locationSelectInput.addEventListener('keypress', this.preventNumericalInput);

    // Setup location search field
    autocomplete({
      input: this.locationSelectInput,
      showOnFocus: true,
      className: 'location-select__dropdown',
      preventSubmit: true,
      fetch: (text, update) => {

        // Lower Case Text
        const lowerCaseText = text.toLowerCase();

        // Get suggestions
        const suggestions = this.locationOptions.filter((location) => (
          location.label.toLowerCase().includes(lowerCaseText)
        ));

        // Add 'Other' to bottom of suggestions
        suggestions.push({ label: this.locationOtherLabel, value: 'other' });

        // Update the suggestions
        update(suggestions);
      },
      onSelect: (item) => {
        this.locationSelectInput.value = item.label;

        const isOtherLocationSelected = item.value.toString().toLowerCase() === 'other';

        // If batch not defined or other is selected, display the first batch as default
        if (isOtherLocationSelected || !(item.value in this.batches)) {
          this.displayBatch(0);
        } else {
          this.displayBatch(this.batches[item.value]);
        }

        this.populateRecipientSelect(item);

        // Validate the form
        this.validateForm();
      },
      render: (item) => {
        const itemElement = document.createElement('p');
        itemElement.classList.add('location-select__dropdown-item');
        itemElement.textContent = item.label;
        itemElement.tabIndex = 0;
        return itemElement;
      },
      customize: (input, inputRect, container) => {
        container.style.maxHeight = '224px';
      },
    });
  }

  /**
   * initRecipientsInputEvents
   */
  initRecipientsInputEvents() {
    this.recipientInput.addEventListener('input', () => {
      this.validateForm();
    });
  }

  /**
   * updateRecipients
   *
   * @param {Array} contacts
   */
  updateRecipients(contacts) {
    this.selectedContacts = contacts;

    // Validate form fields
    this.validateForm();
  }

  /**
   * populateRecipientSelect
   *
   * @param {Object} item
   */
  populateRecipientSelect(item) {

    // If "Other" is selected
    if (item.value.toString().toLowerCase() === 'other') {
      this.recipientInput.disabled = false;
      this.recipientInput.value = '';
      this.recipientSelect.classList.remove('recipient-select--static');
      this.recipientSelect.classList.add('recipient-select--enabled');
      this.updateRecipients([]);
      return;
    }

    // Get contacts array
    const contacts = this.locations[item.label];

    this.recipientSelect.classList.add('recipient-select--static');
    this.recipientSelect.classList.remove('recipient-select--enabled');
    // If selected location only has 1 contact
    if (contacts.length === 1) {
      this.recipientInput.value = `${contacts[0].title} ${contacts[0].name}`;
      this.updateRecipients(contacts);
    } else {
      // If selected location has multiple contacts
      this.recipientInput.value = this.recipientLabel.dataset.multipleValue;
      this.updateRecipients(contacts);
    }
    this.recipientLabel.innerText = this.recipientInput.value;
  }

  /**
   * initNameinputEventListener
   */
  initNameinputEventListener() {
    this.nameInput.addEventListener('input', () => {

      // Dispalye name in letter footer
      this.closingNameDisplay.innerHTML = this.nameInput.value;

      // Validate the form
      this.validateForm();
    });

    // prevent numerical inputs
    this.nameInput.addEventListener('keypress', this.preventNumericalInput);
  }

  /**
   * displayBatch
   *
   * @param {Number} id
   */
  displayBatch(id) {
    this.selectedBatch = id;

    const batch = this.voiceYourConcernSection.querySelector(`.js-letter-batch--${id}`);
    const batches = this.voiceYourConcernSection.querySelectorAll('.js-letter-batch:not([letter-hidden])');

    if (batches) {
      batches.forEach((b) => b.setAttribute('letter-hidden', ''));
    }

    if (batch) {
      batch.removeAttribute('letter-hidden');
    }
  }

  /**
   * validateForm
   */
  validateForm() {
    const location = this.locationSelectInput.value.trim().length > 0;
    const recipientsInputValue = this.recipientInput.value.trim().length > 0;
    const contacts = this.selectedContacts;

    if (contacts && location && recipientsInputValue) {
      this.generateLetterMailtoLink(contacts);
      this.generateLetterButton.classList.remove('generate-letter-send-button--disabled');
    } else {
      this.generateLetterButton.classList.add('generate-letter-send-button--disabled');
    }
  }

  /**
   * listens for generate letter click, shows success message and triggers the mailto
   * functionality
   */
  initGenerateLetterButtonEvents() {
    this.generateLetterButton.addEventListener('click', () => {
      this.letterSentMessage.removeAttribute('hidden');
      this.letterContentPreview.setAttribute('hidden', '');
      this.generateLetterButtonWrapper.setAttribute('hidden', '');

      this.letterPreviewContainer.classList.add('generate-letter-preview--success');

      this.modalNode.scrollTop = 0;

      axios({
        method: 'post',
        url: '/wp-json/ctcp/v1/increment-letter-count/',
        data: {},
      });
    });
  }

  generateLetterMailtoLink(contacts) {

    // Check if there are multiple recipients
    const multipleRecipients = contacts.length > 1;

    // Batch Dataset
    const batchData = this.voiceYourConcernSection.querySelector(`.js-letter-batch--${this.selectedBatch}`).dataset;

    // Preview Dataset
    const previewDataset = this.letterContentPreview.dataset;

    // Sender Name
    const senderName = this.nameInput.value;

    // Recipients ('to' and 'bcc')
    const recipientsEmailAddresses = contacts.map((contact) => contact.email);
    const to = multipleRecipients ? '' : recipientsEmailAddresses[0] || '';
    const bcc = multipleRecipients ? recipientsEmailAddresses.join(',') : '';

    // Subject
    const { englishSubject } = batchData;

    // Opening
    const englishOpening = `${previewDataset.englishDear} ${multipleRecipients ? previewDataset.englishAllContacts : this.recipientInput.value}`;

    // Body
    const letterBodyConverter = document.createElement('div');
    letterBodyConverter.innerHTML = batchData.englishBody;
    const englishBody = letterBodyConverter.textContent.replace(/  +/g, ' ').trim();

    // Closing
    const englishClosing = `${previewDataset.englishSincerely}\r\n\r\n${senderName}`;

    // Create mailto link
    const mailToLinkBuilder = new URL(`mailto:${to}`);
    if (bcc) mailToLinkBuilder.searchParams.append('bcc', bcc);
    mailToLinkBuilder.searchParams.append('subject', englishSubject);
    mailToLinkBuilder.searchParams.append('body', `${englishOpening}\r\n\r\n${englishBody}\r\n\r\n${englishClosing}`);

    // Replaces '+' with '%20' for compatability with older email clients
    this.generateLetterButton.href = mailToLinkBuilder.toString().replaceAll('+', '%20');
  }
}

export default VoiceYourConcernV2;
