class VideoModal {
  constructor(videoModalId) {
    /**
     * @type {HTMLElement}
     */
    this.modal = document.getElementById(videoModalId);

    if (!this.modal) return;

    /**
     * @type {HTMLElement}
     */
    this.videoModalId = videoModalId;

    /**
     * @type {Element}
     */
    this.modalCloseBtn = this.modal.getElementsByClassName('modal-close')[0];

    /**
     * @type {Element}
     */
    this.iframe = Array.from(this.modal.getElementsByTagName('iframe'))[0];

    this.setupModalEvents();
  }

  /**
   * Initialises modal events
   */
  setupModalEvents() {
    this.modal.addEventListener('modalClose', (event) => {
      const video = event.target.querySelector('iframe');
      video.src = '';
    });
  }

  /**
   * Listens for a click on the video carousel and opens
   * a modal with the corresponding video
   * @param {string} youtubeId - ID of a YouTube video to open
   */
  open(youtubeId) {
    const origin = `${encodeURIComponent(window.location.protocol)}//${window.location.hostname}`;
    const ytframe = `https://www.youtube.com/embed/${youtubeId}`;
    this.iframe.src = `${ytframe}?rel=0&amp;autoplay=1&amp;enablejsapi=1&amp;origin=${origin}`;
    // Modal is opened using a data attribute
    this.modalCloseBtn.focus();
  }
}

export default VideoModal;
