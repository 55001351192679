import axios from 'axios';
import validateEmailAddress from '../../utilities/validateEmailAddress';

/**
 * @name ShareYourStory
 * @description Class to apply functionality to the Share Your Story component
 */
class ShareYourStory {
  constructor(containerElement) {

    /**
     * @type {HTMLElement}
     */
    this.shareYourStory = containerElement;

    if (this.shareYourStory) {

      /**
       * @type {HTMLElement}
       */
      this.submitMessage = this.shareYourStory.getElementsByClassName('js-testimonials-form-submit-message')[0];

      /**
       * @type {HTMLElement}
       */
      this.testimonialsForm = this.shareYourStory.getElementsByClassName('js-testimonials-form')[0];
      this.testimonialsForm.loading = false;

      /**
       * @type {HTMLElement}
       */
      this.textarea = this.testimonialsForm.getElementsByClassName('js-testimonials-form-textarea')[0];

      /**
       * @type {HTMLElement}
       */
      this.checkbox = this.testimonialsForm.getElementsByClassName('js-testimonials-form-checkbox')[0];

      /**
       * @type {HTMLElement}
       */
      this.emailWrapper = this.testimonialsForm.getElementsByClassName('js-testimonials-form-email-wrapper')[0];

      /**
       * @type {HTMLElement}
       */
      this.email = this.testimonialsForm.getElementsByClassName('js-testimonials-form-email')[0];

      /**
       * @type {HTMLElement}
       */
      this.submitButton = this.testimonialsForm.getElementsByClassName('js-testimonials-form-submit')[0];
      this.submitButton.initialValue = this.submitButton.innerText;

      /**
       * @type {HTMLElement}
       */
      this.honeyPot = this.testimonialsForm.getElementsByClassName('js-testimonials-form-hp')[0];

      /**
       * @type {HTMLElement}
       */
      this.errorMessage = this.testimonialsForm.getElementsByClassName('js-error-message')[0];

      /**
       * @type {Object}
       */
      this.emailConfig = {
        email_message: this.shareYourStory.dataset.emailMessage,
        recipient_email: this.shareYourStory.dataset.recipientEmail,
        email_subject: this.shareYourStory.dataset.emailSubject,
        error_massages: JSON.parse(this.shareYourStory.dataset.errorMassages),
      };

      this.initForm();
    }
  }

  /**
   * Initialise the form event listeners
   *
   * @method initForm
   * @memberof StartConversation
   */
  initForm() {
    this.textarea.addEventListener('input', () => {
      this.toggleSubmitButton();
    });

    this.email.addEventListener('input', () => {
      this.toggleSubmitButton();
    });

    // Additional events to detect autofill, although may not be fully detected.
    // @link: https://stackoverflow.com/questions/11708092/detecting-browser-autofill
    this.email.addEventListener('change', () => {
      this.toggleSubmitButton();
    });

    this.email.addEventListener('blur', () => {
      this.toggleSubmitButton();
    });

    this.checkbox.addEventListener('change', (event) => {

      // If checkbox is checked then show additional field and set field to required,
      // otherwise hide and set to not required
      if (event.target.checked) {
        this.emailWrapper.classList.add('share-your-story__testimonials-form-email--enabled');
        this.email.required = true;
      } else {
        this.emailWrapper.classList.remove('share-your-story__testimonials-form-email--enabled');
        this.email.required = false;
      }

      this.toggleSubmitButton();
    });

    this.testimonialsForm.addEventListener('submit', (event) => {
      event.preventDefault();

      // Check that honeypot is not filled.
      if (this.honeyPot.value.length === 0 && !this.testimonialsForm.loading && this.validForm()) {
        this.testimonialFormStartLoading();

        const dataObj = {
          story: this.textarea.value,
        };

        // If the checkbox is checked
        if (this.checkbox.checked) {
          dataObj.email = this.email.value;
        }

        // Add configuration to data object
        dataObj.config = this.emailConfig;

        // Make API request
        axios({
          method: 'post',
          url: '/wp-json/ctcp-testimonial/v1/create-testimonial/',
          data: dataObj,
        })
          .then(() => {
            // Show submit success message
            this.testimonialsForm.hidden = true;
            this.submitMessage.hidden = false;

            // Hide error message
            this.errorMessage.classList.remove('js-error-message--active');
          })
          .catch((error) => {
            this.submitButton.disabled = false;
            // eslint-disable-next-line no-console
            console.warn(error);

            if (error.response.data.message) {
              // Display error
              // eslint-disable-next-line no-console
              console.warn(error.response.data.message);
              this.errorMessage.innerText = error.response.data.message;
              this.errorMessage.classList.add('js-error-message--active');
            }
          })
          .then(() => {
            // stop loading
            this.testimonialFormStopLoading();
          });
      }
    });
  }

  validForm() {

    // Minimum length of 5 characters for text area
    const textAreaFilled = (this.textarea.value.length >= 5);

    // Boolean true/false response from checkbox
    const emailRequired = this.checkbox.checked;

    // Ensure the minimum amount of characters for an email are present
    const emailFilled = (this.email.value.length >= 6);

    // Check if the browser validation state is valid
    const emailValid = this.email.validity.valid && validateEmailAddress(this.email.value);

    // Check text area is filled, if email is required then check it's filled and valid
    return (textAreaFilled && (emailRequired ? (emailFilled && emailValid) : true));
  }

  /**
   * Toggle the submit button of the form if it has passed validation
   *
   * @method toggleSubmitButton
   * @memberOf StartConversation
   * @return null [No return for this method]
   */
  toggleSubmitButton() {
    this.submitButton.disabled = !this.validForm();
  }

  /**
   * Add loading state to testimonial form
   *
   * @method testimonialFormStartLoading
   * @memberof StartConversation
   */
  testimonialFormStartLoading() {
    this.submitButton.innerText = '';
    this.testimonialsForm.loading = true;
    this.testimonialsForm.classList.add('share-your-story__testimonials-form--loading');
  }

  /**
   * Remove loading state from testimonial form
   *
   * @method testimonialFormStopLoading
   * @memberof StartConversation
   */
  testimonialFormStopLoading() {
    this.submitButton.innerText = this.submitButton.initialValue;
    this.testimonialsForm.loading = false;
    this.testimonialsForm.classList.remove('share-your-story__testimonials-form--loading');
  }
}

export default ShareYourStory;
