import zenscroll from 'zenscroll';
import _ from 'lodash';

class ZenscrollSetup {
  constructor() {
    /**
     * @type {string}
     */
    this.language = document.documentElement.lang;

    /**
     * @type {?number}
     */
    this.offset = null;

    this.setupZenscroll();
    this.initListeners();
  }

  /**
   * Detects the language of the site and adjusts the scroll offset
   * due to different header sizes
   *
   *
   * @method setupZenscroll
   * @memberof ZenscrollSetup
   */
  setupZenscroll() {
    switch (this.language) {
      case 'es-ES': {
        this.offset = window.innerWidth >= 768 ? 102 : 76;
        break;
      }
      case 'en-US':
      default: {
        this.offset = window.innerWidth >= 768 ? 76 : 58;
        break;
      }
    }

    zenscroll.setup(null, this.offset);
  }

  /**
   * Listens for screen resize a runs the zenscroll setup again
   *
   * @method setupZenscroll
   * @memberof ZenscrollSetup
   */
  initListeners() {
    window.addEventListener('resize', _.debounce(this.setupZenscroll.bind(this), 300));
  }
}

export default ZenscrollSetup;
