import axios from 'axios';
import FlashMessages from '../../components/flash-messages';

/**
 * @name TheGuideV2
 * @description Class to apply functionality to 'The Guide' component
 */
class TheGuideV2 {
  constructor(containerElement) {

    /**
     * @type {HTMLElement}
     */
    this.theGuideSection = containerElement;

    if (!this.theGuideSection) return;

    /**
     * The list of checkboxes associated with the PDFs available to be downloaded.
     * @type {Element[]}
     */
    this.checkboxes = Array.from(this.theGuideSection.getElementsByClassName('js-pdf-checkbox'));

    /**
     * Selected checkboxes - counter text in the CTA
     * @type {Element}
     */
    this.selectedCheckboxesCounterText = this.theGuideSection.querySelector('.js-selected-counter');

    this.cta = this.theGuideSection.querySelector('.js-cta');

    this.setCheckboxesDefaultState();
    this.initCheckboxesListeners();
    this.initCtaListener();
  }

  /**
   * Initialize event listeners for checkboxes.
   */
  initCheckboxesListeners() {
    for (const checkbox of this.checkboxes) {
      /* We could also listen to 'change' event, but we need to stop the
      * propagation of a 'click' event to the accordion's trigger, so need
      * to listen to the 'click' event anyway */
      checkbox.addEventListener('click', (event) => {
        event.stopPropagation();
        this.updateValidityAndCounter();
      });
    }
  }

  /**
   * Update the state of a CTA and its counter label
   * based on the "checked" state of the checkboxes.
   */
  updateValidityAndCounter() {
    const checkedBoxesCounter = this.checkboxes
      .map((checkboxEl) => +checkboxEl.checked)
      .reduce((acc, value) => acc + value, 0);

    this.cta.disabled = checkedBoxesCounter === 0;
    this.selectedCheckboxesCounterText.innerText = checkedBoxesCounter > 1 ? `(${checkedBoxesCounter})` : '';
  }

  /**
   * Set the default "checked" state for the checkboxes.
   */
  setCheckboxesDefaultState() {
    if (this.checkboxes.length > 0) {
      this.checkboxes[0].checked = true;
      this.updateValidityAndCounter();
    }
  }

  /**
   * Initialize the click event listener for a CTA.
   */
  initCtaListener() {
    if (!this.cta || this.checkboxes.length === 0) return;

    this.cta.addEventListener('click', (event) => {
      event.stopPropagation();
      const filesToOpen = this.checkboxes
        .filter((checkboxEl) => checkboxEl.checked)
        .map((checkedBox) => checkedBox.dataset.guideFile);

      /* If there is one file to be opened, open it in a new tab.
      Otherwise, send a POST request to zip the multiple files
      and get a link to that zip file.
       */
      if (filesToOpen.length === 1) {
        window.open(filesToOpen[0]);
      } else {
        axios({
          method: 'post',
          url: '/wp-json/ctcp-guide/get-bundle/',
          data: {
            files: filesToOpen,
          },
        })
          .then((result) => {
            if (result.status === 200) {
              const downloadLink = document.createElement('a');
              downloadLink.download = 'flavors-guides';
              downloadLink.href = result.data.path;
              downloadLink.click();
            } else {
              (new FlashMessages()).add(null, { type: 'error' }).show();
            }
          })
          .catch((error) => {
            (new FlashMessages()).add(null, { type: 'error' }).show();
          });
      //  TODO: consider adding some error handling here if the api fails
      }

    });
  }
}

export default TheGuideV2;
